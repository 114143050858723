<template>
	<div>

		<div v-for="(product, productIndex) in products" v-bind:class="{ 'is-disabled': !enabled, 'product__container': true }">

        <div class="row">
          
          <div class="thumbnail-container" v-bind:style="{ 'background-image': 'url('+getThumbnail(product.productId)+')' }">
          </div>

          <div class="item-container">
            <div class="item-wrap">
              <div v-for="(item, itemIndex) in product.items" class="item row">
                
                <div v-if="itemIndex === 0">
                  <h3>{{ product.name }}</h3>
                </div>
                <div class="row form-actions">
                  <product :product="item.data" :product-type-id="product.productId" v-bind:is-disabled="!enabled"></product>

                  <button class="button plain delete-product"
                    v-if="product.items.length > 1"
                    v-on:click="removeItemFromProduct({ 'productIndex': productIndex, 'itemIndex': itemIndex })"
                    v-bind:disabled="!enabled">
                      Delete product
                  </button>
                </div>
      	      </div> <!-- //end .item-wrap -->
            </div>

            <div class="row">
              <div class="product__notes">
                <label v-bind:for="'product-form-notes-' + productIndex">Notes</label>
                <textarea
                  placeholder="Add notes here. Can include what this product will be used for, selection criteria, questions for others to answer. Notes can also provide project history and save time on your next project."
                  v-bind:id="'product-form-notes-' + productIndex"
                  v-model="product.notes"
                  v-bind:disabled="!enabled" />
              </div><!-- //end .product__notes -->

              <div class="product__actions">
                <button class="button tertiary"
                  v-on:click="addItemToProduct({ 'productIndex': productIndex, 'productId': product.productId })"
                  v-bind:disabled="!enabled">
                    Add another
                </button>
                <br>
                <button class="button plain"
                  v-on:click="removeProduct(productIndex)"
                  v-bind:disabled="!enabled">
                    Delete product
                </button>
              </div><!-- //end .product__actions -->
            </div> <!-- //end .product-group__form -->
          </div><!-- //end .item-container -->
        
        </div> <!-- //end .row -->

		</div> <!-- //end .product__container -->

	</div>
</template>

<script>
import { thumbnails } from './schema';
import { mapState, mapMutations } from 'vuex'
import Product from './Product.vue';

export default {
  name: 'productList',

  data () {
    return {}
  },

  methods : {
  	...mapMutations([
      'addItemToProduct',
  	  'removeProduct',
      'removeItemFromProduct'
  	]),

    getThumbnail(id) {
      if(id){
        return thumbnails[id];
      } else {
        return thumbnails['default'];
      }
    }
  },

  computed: {
  	...mapState([
      'enabled',
  	  'products'
    ])
  },

  components: {
  	Product
  }
}
</script>

<style src="./scss/product.scss" lang="scss" scoped></style>

<style lang="scss" scoped>
  .title {
    margin-bottom: 1em;
    overflow: auto;
  }

  h3 {
    font-weight: bold;
    font-size: 15px;
    color: #00273B;
    letter-spacing: 0;
    line-height: 19px;
    padding-bottom: 23px;
  }

 .product__container {
    border-bottom: 1px solid #B5D1D9;
     padding-top: 20px;
     padding-bottom: 36px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    .row {
      margin-bottom:  0;

      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }

  .form-actions {
    position: relative;
  }

  .delete-product {
    position: absolute;
    right: 0;
    bottom: 0;
  }
</style>
