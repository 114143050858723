/*
EcoBattInsulation.js
*/

const EcoBattInsulation = {
  productId: 1,
  name: 'EcoBatt® Insulation',
  defaultModel: {
    rValue: null,
    thickness: null,
    facer: 'Unfaced',
    amount: 1,
  },
  formSchema: {
    fields: [
      {
        type: 'select',
        label: 'R-value',
        model: 'rValue',
        values: [3, 5, 8, 11, 12, 13, 14, 15, 19, 20, 21, 22, 23, 24, 25, 26, 28, 30, 31, 35, 38, 40, 49],
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style', 
        onChanged(model, val){
          model.thickness = null;
        },
        disabled() { return this.$parent.isDisabled }
      },
      {
        type: 'select',
        label: 'Thickness',
        model: 'thickness',
        values(model, schema) {
          switch(model.rValue) {
            case 3:
              return ['0.75\"'];
              break;
            case 5:
              return ['1.5\"'];
              break;
            case 8:
              return ['2.5\"'];
              break;
            case 11:
            case 12:
            case 13:
              return ['3.5\"'];
              break;
            case 14:
              return ['6\"'];
              break;
            case 15:
              return ['3.5\"'];
              break;
            case 19:
              return ['6.25\"'];
              break;
            case 20:
              return ['6\"', '5.5\"'];
              break;
            case 21:
              return ['5.5\"'];
              break;
            case 22:
              return ['5.5\"', '6.5\"'];
              break;
            case 23:
              return ['5.5\"'];
              break;
            case 24:
              return ['5.5\"'];
              break;
            case 25:
              return ['8\"'];
              break;
            case 26:
              return ['9\"'];
              break;
            case 28:
              return ['8\"', '7\"'];
              break;
            case 30:
              return ['8.25\"', '10\"', '9.5\"'];
              break;
            case 31:
              return ['9.5\"'];
              break;
            case 35:
              return ['10\"'];
              break;
            case 38:
              return ['10.25\"', '12\"'];
              break;
            case 40:
              return ['11.5\"'];
              break;
            case 49:
              return ['13.5\"'];
              break;
            default:
              return [];
           }
        },
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled(model) { return this.$parent.isDisabled || !model.rValue }
      },
      {
        type: 'select',
        label: 'Facer',
        model: 'facer',
        values: ['Unfaced', 'Kraft Faced', 'FSK (foil-scrim-kraft)', 'Foil-Kraft (v. limited use)'],
        required: true,
        selectOptions: { hideNoneSelectedText: true },
        styleClasses: 'select-style',
        disabled() { return this.$parent.isDisabled }
      },
      { 
        type: 'input',
        inputType: 'text',
        maxlength: 10,
        label: 'Amount',
        model: 'amount',
        pattern: '\\d*',
        onChanged(model, newVal, oldVal, field) {
          newVal = newVal.toString().replace(/\D/g,'');
          model.amount = newVal;
        },
        disabled() { return this.$parent.isDisabled; },
        styleClasses: 'uom-ft'
      }
    ]
  }
};

export { EcoBattInsulation }