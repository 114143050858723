/*
  AtmosphereDuctLiner.js
*/

const KnUtilityInsulation = {
  productId: 7,
  name: 'KN Utility Insulation',
  defaultModel: {
    density: null,
    thickness: null,
    rValue: null,
    facer: 'Unfaced',
    amount: 1,
  },
  formSchema: {
    fields: [
      {
        type: 'select',
        label: 'Density, PCF',
        model: 'density',
        values: [0.75, 1, 1.5],
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled() { return this.$parent.isDisabled },
        onChanged(model, val) {
          model.thickness = null;
          model.rValue = null;
        }
      },
      {
        type: 'select',
        label: 'Thickness',
        model: 'thickness',
        values(model, schema) {
          switch(model.density) {
            case 0.75:
              return [ '1\"', '1.5\"', '2\"', '2.1875\"', '2.5\"', '3\"' ];
              break;
            case 1:
              return [ '1\"', '1.5\"', '2\"' ];
              break;
            case 1.5:
              return [ '1\"', '1.5\"', '2\"' ];
              break;
            default:
              return null;
          }
        },
        onChanged(model, val) {
          if( model.density == 0.75) {
            switch(val) {
              case '1\"':
                model.rValue = 2.8;
                break;
              case '1.5\"':
                model.rValue = 4.2;
                break;
              case '2\"':
                model.rValue = 5.6;
                break;
              case '2.1875\"':
                model.rValue = 6;
                break;
              case '2.5\"':
                model.rValue = 7;
                break;
              case '3\"':
                model.rValue = 8.4;
                break;
              default:
                return;
            }
          }

          if( model.density == 1) {
            switch(val) {
              case '1\"':
                model.rValue = 3;
                break;
              case '1.5\"':
                model.rValue = 4.5;
                break;
              case '2\"':
                model.rValue = 6;
                break;
              default:
                return;
            }
          }

          if( model.density == 1.5){
            switch(val) {
              case '1\"':
                model.rValue = 3.2;
                break;
              case '1.5\"':
                model.rValue = 4.8;
                break;
              case '2\"':
                model.rValue = 6.4;
                break;
              default:
                return;
            }
          }
        },
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled(model) { return this.$parent.isDisabled || !model.density}
      },
      {
        type: 'select',
        label: 'Installed R-value',
        model: 'rValue',
        values(model, schema) {
          if( model.density == 0.75) {
            switch(model.thickness) {
              case '1\"':
                return [2.8];
                break;
              case '1.5\"':
                return [4.2];
                break;
              case '2\"':
                return [5.6];
                break;
              case '2.1875\"':
                return [6];
                break;
              case '2.5\"':
                return[7];
                break;
              case '3\"':
                return [8.4];
                break;
              default:
                return;
            }
          }

          if( model.density == 1) {
            switch(model.thickness) {
              case '1\"':
                return [3];
                break;
              case '1.5\"':
                return [4.5];
                break;
              case '2\"':
                return [6];
                break;
              default:
                return;
            }
          }

          if( model.density == 1.5){
            switch(model.thickness) {
              case '1\"':
                return [3.2];
                break;
              case '1.5\"':
                return [4.8];
                break;
              case '2\"':
                return [6.4];
                break;
              default:
                return;
            }
          }
        },
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled(model) { return this.$parent.isDisabled || !model.density || !model.thickness }
      },
      {
        type: 'select',
        label: 'Facer',
        model: 'facer',
        values: ['Unfaced', 'FSK (foil-scrim-kraft)'],
        required: true,
        selectOptions: { hideNoneSelectedText: true },
        styleClasses: 'select-style',
        disabled() { return this.$parent.isDisabled }
      },
      {
        type: 'input',
        inputType: 'text',
        maxlength: 10,
        label: 'Amount',
        model: 'amount',
        pattern: '\\d*',
        onChanged(model, newVal, oldVal, field) {
          newVal = newVal.toString().replace(/\D/g,'');
          model.amount = newVal;
        },
        disabled() { return this.$parent.isDisabled; },
        styleClasses: 'uom-ft'
      },
    ]
  }
};

export { KnUtilityInsulation }