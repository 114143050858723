/*
  store/index.js
*/
import { HTTP } from './../http-common';
import { schema } from './../schema';
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    enabled: false,

    isLoading: false,

    errors: [],

    newUserErrors: [],

    loginErrorMessage: null,

    showLoginModal: false,

    showContactModal: false,

    contactProducts: null,

    showRegistrationForm: false,

    showGlossaryModal: false,

    showProjectSavedModal: false,

    showSpinner: false,

    showLoginSpinner: false,

    formInitalized: false,

    productFormIsVisible: false,

    initProductId: false,

    initProjectId: false,

    saveProjectAfterAuth: false,

    userId: null,

    project: {
      name: null,
      client: null,
      category: null,
      description: null,
    },

    products: []
  },

  getters: {
    getProductByIndex: (state, getters) => (index) => {
      return state.products[index];
    },

    findProductIndexByProductId: (state, getters) => (productId) => {
      let productIndex = state.products.findIndex(product => product.productId == productId);

      return productIndex;
    },

    // Returns array of productIds not currently added to the project.
    // Helpful if we want to propulate a product type select input, like within the ProductForm.vue
    availableProducts: (state, getters) => {

      // Get all the uniqe productId from the project
      let activeProducts = [];

      state.products.filter( product => {
        var i = activeProducts.findIndex(x => x == product.productId);
        if(i <= -1) activeProducts.push(parseInt(product.productId, 10));
      });

      window.activeProducts = activeProducts;

      // Return a list of available products ids from the schema by filtering out the productIds that are in use.
      let available = [];

      available = schema.map(product => {
        // 1, 2, 3

        // if active products does not contain the schema productId, add it to the collection
        if ( activeProducts.indexOf(product.productId) == -1) return product.productId;
      });

      return available;
    }
  },

  mutations: {
    isEnabled: (state) => {
      state.enabled = true;
    },

    isInitalized: (state) => {
      state.formInitalized = true;
    },

    handleError: (state, error) => {
      if (error.response) {
        if (error.response.message) {
          state.errors.push(error.response.message);
        } else {
          state.errors.push('Error: '+error.response.status+' - '+error.response.statusText);
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        state.errors.push('An error has occured');
        console.log('Error:', error);
      }
    },

    setIsLoading (state, bool) {
      bool = (typeof bool === "undefined") ? true : bool;
      state.isLoading = bool;
    },

    setErrorMessage (state, message) {
      state.errors.push(message);
    },

    setNewUserErrors (state, arr) {
      state.newUserErrors = arr;
    },

    setLoginErrorMessage (state, string) {
      state.loginErrorMessage = string;
    },

    setSaveProjectAfterAuth(state, bool) {
      state.saveProjectAfterAuth = bool;
    },

    setUserId (state, id) {
      state.userId = id;
    },

    toggleProductForm: function(state){
      state.productFormIsVisible = !state.productFormIsVisible;
    },

    toggleLoginModal: function(state){
      state.showLoginModal = !state.showLoginModal;
    },

    closeLoginModal: function(state){
      state.showLoginModal = false;
      state.showRegistrationForm = false;
    },

    toggleContactModal: function(state, string){
      if(string){ 
        state.contactProducts = string; 
      } else {
        state.contactProducts = null; 
      }
      state.showContactModal = !state.showContactModal;
    },

    showRegistrationForm(state) {
      state.showRegistrationForm = true;
      state.showLoginModal = true;
    },

    setRegistrationForm (state, bool) {
      state.showRegistrationForm = bool;
    },

    toggleProjectSavedModal: function(state){
      state.showProjectSavedModal = !state.showProjectSavedModal;
    },

    toggleGlossaryModal: function(state){
      state.showGlossaryModal = !state.showGlossaryModal;
    },

    toggleSpinner: function(state){
      state.showSpinner = !state.showSpinner;
    },

    toggleLoginSpinner: function(state){
      state.showLoginSpinner = !state.showLoginSpinner;
    },

    setInitProductId: function(state, id){
      state.initProductId = id;
    },

    setInitProjectId: function(state, id){
      state.initProjectId = id;
    },

    loadProject: function(state, project){

      state.products = [...project.products, ...state.products];

      let {products, ...projectData} = project;

      state.project = projectData;
    },

    setProjectName: function(state, string){
      if (!string) state.enabled = false;

      if (string && state.formInitalized) state.enabled = true;

      state.project.name = string;
    },

    setProjectClient: function(state, string){
      state.project.client = string;
    },

    setProjectCategory: function(state, string){
      state.project.category = string;
    },

    setProjectDescription: function(state, string){
      state.project.description = string;
    },

    handleProjectSave: function(state, obj){
      state.project.id = obj.id;
      state.showProjectSavedModal = true;
    },

    addProduct: function(state, product){
      if(product.productId) {

        // check if product exists in products array.
        let productIndex = this.getters.findProductIndexByProductId(product.productId);

        if(productIndex > -1) {
          // if so, add product item to the existing product.
          let item = product.items[0];
          let existingProduct = this.getters.getProductByIndex(productIndex);

          if (existingProduct) { existingProduct.items.push(item); }
        } else {
          // if not add the product
          state.products.push(product);
        }
      }

    },

    removeProduct: function(state, index){
      if (index > -1) this.state.products.splice(index, 1);
    },

    addItemToProduct: function(state, obj){
      if (obj.productIndex > -1 && obj.productId) {

        var schemaIndex = schema.findIndex(o => o.productId == obj.productId);

        let item = {
          data: { ...schema[schemaIndex].defaultModel }
        };

        let product = this.getters.getProductByIndex(obj.productIndex);

        if (product) product.items.push(item);
      }
    },

    removeItemFromProduct: function(state, obj){
      if (obj.productIndex >= 0 && obj.itemIndex >= 0) {

        if (state.products[obj.productIndex]) state.products[obj.productIndex].items.splice(obj.itemIndex, 1);

        if (!Array.isArray(state.products[obj.productIndex].items) || !state.products[obj.productIndex].items.length) {
          this.commit('removeProduct', obj.productIndex);
        }
      }
    },
  },

  actions: {
    initProject ({commit, state}) {
      commit('setIsLoading');

      HTTP.get('projects/'+state.initProjectId)
        .then(response => {
          let project = response.data.data[0];

          commit('loadProject', project);
          commit('isInitalized');
          commit('isEnabled');
          commit('toggleSpinner');
          commit('setIsLoading', false);
        })
        .catch(e => {
          commit('handleError', e);
          commit('toggleSpinner');
          commit('setIsLoading', false);
       })
    },

    loginUser({ commit, state }, formData){

      commit('setLoginErrorMessage', null);

      if(!state.userId) {
        commit('setIsLoading', true);
        commit('toggleLoginSpinner');

        HTTP.post('/', formData)
          .then(response => {
            if(response.data.error) 
            {
              commit('setLoginErrorMessage', response.data.error);
              commit('setIsLoading', false);
              commit('toggleLoginSpinner');
            } 
            else 
            {
              if(window.REDIRECT)
              {
                location.reload();
              } 
              else 
              {
                commit('setUserId', response.data.currentUser);
                commit('setIsLoading', false);
                commit('toggleLoginSpinner');
                commit('closeLoginModal');

                // if we have have a project to be saved after login, do it.
                if(state.saveProjectAfterAuth) this.dispatch('saveProject');
              }
            }
          })
          .catch(e => {
            commit('handleError', e);
            commit('setIsLoading', false);
            commit('toggleLoginSpinner');
            commit('closeLoginModal');
          })
      }
    },

    registerNewUser({ commit, state }, formData){
      commit('setIsLoading', true);
      commit('toggleLoginSpinner');

      commit('setNewUserErrors', null);

      if(!state.userId) {
        HTTP.post('/', formData)
          .then(response => {
            if(response.data.errors) 
            {
              commit('setNewUserErrors', response.data.errors);
              commit('setIsLoading', false);
              commit('toggleLoginSpinner');
            } 
            else 
            {
              console.log(response.data.id);
              if(window.REDIRECT)
              {
                location.reload();
              } 
              else 
              {
                commit('setUserId', response.data.id);
                commit('setIsLoading', false);
                commit('toggleLoginSpinner');
                commit('closeLoginModal');

                // if we have have a project to be saved after login, do it.
                if(state.saveProjectAfterAuth) this.dispatch('saveProject');
              }
            }
          })
          .catch(e => {
            commit('handleError', e);
            commit('setIsLoading', false);
            commit('toggleLoginSpinner');
            commit('closeLoginModal');
          })
      }
    },

    createProject({commit, state}, payload){
      // Create new project
      HTTP.post('actions/vuePost/projects/saveProject', payload)
        .then(response => {
          if(response.data.data) {
            commit('handleProjectSave', response.data.data);
          } else {
            commit('handleError', 'Project data not found.');
          }
          commit('toggleSpinner');
          commit('setIsLoading', false);
        })
        .catch(e => {
          commit('handleError', e);
          commit('toggleSpinner');
          commit('setIsLoading', false);
        })
    },

    updateProject({commit, state}, payload){
      // Update project
      HTTP.put('actions/vuePost/projects/saveProject/'+payload.id, payload)
        .then(response => {
          if(response.data.data) {
            commit('handleProjectSave', response.data.data);
          } else {
            commit('handleError', 'Project data not found.');
          }
          commit('toggleSpinner');
          commit('setIsLoading', false);
        })
        .catch(e => {
          commit('handleError', e);
          commit('toggleSpinner');
          commit('setIsLoading', false);
        })
    },

    saveProject ({ commit, state }) {
      if(state.isLoading) return false; // app is already processing a request, exit

      if(state.saveProjectAfterAuth) commit('setSaveProjectAfterAuth', false); // reset saveProjectAfterAuth
      
      if(state.userId) 
      { 
        // user is logged in, save the project
        let payload = {
          ...this.state.project,
          products: [ ...this.state.products]
        };

        commit('setIsLoading');
        commit('toggleSpinner');

        if (payload.id)
        {
          // Updates existing project entry
          this.dispatch('updateProject', payload)
        }
        else
        {
          // Create a new project entry
          this.dispatch('createProject', payload)
        }
      } 
      else 
      {
        // User must authenticate
        commit('toggleLoginModal');
        commit('setIsLoading', false);
        // Let the loginUser action know we need to call saveProject after authenticating.
        commit('setSaveProjectAfterAuth', true);
      }
    },
  }
});