<template>
  <div v-bind:class="{ 'is-disabled': !enabled, 'product-form__container': true, 'row': true }">
      <hr v-show="products.length > 0">

      <h4>Add new product</h4>

      <div class="product-group row">

        <div class="thumbnail-container" v-bind:style="{ 'background-image': 'url('+getThumbnail(selectedProductId)+')' }">
        </div>

        <div class="item-container">
          <div class="item-wrap row">
            <div class="item row">
              <div class="form-actions row">
                <div class="field-group product-field">
                  <label for="product-form-type">Product</label>
                  <div class="field-wrap">
                    <select id="product-form-type" v-model="selectedProductId" v-on:change="handleSelectProduct" v-bind:disabled="!project.name">
                      <option disabled value="">Select product</option>

                      <optgroup label="Batt">
                        <option value="1" v-bind:disabled="optionIsDisabled(1)">EcoBatt® Insulation {{ optionIsDisabled(1) ? '(added to project)' : '' }}</option>
                      </optgroup>

                      <optgroup label="Blowing Wool">
                        <option value="2" v-bind:disabled="optionIsDisabled(2)">Jet Stream® Ultra {{ optionIsDisabled(2) ? '(added to project)' : '' }}</option>
                      </optgroup>

                      <optgroup label="Spray-On">
                        <option value="3" v-bind:disabled="optionIsDisabled(3)">JetSpray™ {{ optionIsDisabled(3) ? '(added to project)' : '' }}</option>
                      </optgroup>

                      <optgroup label="Pipe">
                        <option value="4" v-bind:disabled="optionIsDisabled(4)">Earthwool® 1000° {{ optionIsDisabled(4) ? '(added to project)' : '' }}</option>
                      </optgroup>

                      <optgroup label="Duct">
                        <option value="5" v-bind:disabled="optionIsDisabled(5)">Atmosphere™ Duct Liner {{ optionIsDisabled(5) ? '(added to project)' : '' }}</option>
                        <option value="6" v-bind:disabled="optionIsDisabled(6)">Atmosphere™ Duct Wrap {{ optionIsDisabled(6) ? '(added to project)' : '' }}</option>
                        <option value="7" v-bind:disabled="optionIsDisabled(7)">KN Utility Insulation {{ optionIsDisabled(7) ? '(added to project)' : '' }}</option>
                        <option value="8" v-bind:disabled="optionIsDisabled(8)">Wall and Ceiling Liner {{ optionIsDisabled(8) ? '(added to project)' : '' }}</option>
                      </optgroup>

                      <optgroup label="Acoustical">
                        <option value="9" v-bind:disabled="optionIsDisabled(9)">Black Acoustical Board {{ optionIsDisabled(9) ? '(added to project)' : '' }}</option>
                        <option value="10" v-bind:disabled="optionIsDisabled(10)">Acoustical Smooth Board {{ optionIsDisabled(10) ? '(added to project)' : '' }}</option>
                        <option value="11" v-bind:disabled="optionIsDisabled(11)">Earthwool® Insulation Board {{ optionIsDisabled(11) ? '(added to project)' : '' }}</option>
                      </optgroup>
                    </select>
                  </div>
                </div>

                <product v-bind:product-type-id="selectedProductId" ref="newProduct" v-bind:is-disabled="!project.name"></product>
              </div>
            </div>
          </div><!-- //end .item-wrap -->

          <div class="row">
            <div class="product__notes" v-show="selectedProductId">
              <label for="product-form-notes">Notes</label>
              <textarea id="product-form-notes" 
                placeholder="Add notes here. Can include what this product will be used for, selection criteria, questions for others to answer. Notes can also provide project history and save time on your next project."
                v-model="productNotes"
                v-bind:disabled="!project.name">
              </textarea>
            </div><!-- //end .product-notes -->

            <div class="product__actions">
              <button class="button plain" v-on:click="closeForm" v-bind:disabled="!project.name">
                Delete product
              </button>
            </div><!-- //end .product__actions -->
          </div><!-- //end .product-group__form -->

        </div><!-- //end .item-container -->


      </div><!-- //end .product-group -->
  </div>
</template>

<script>
import { schema, thumbnails } from './schema';
import { mapState, mapMutations, mapGetters } from 'vuex';
import Product from './Product.vue';

const DEFAULT_PRODUCT_ID = '';

export default {
  name: 'productForm',

  data () {
    return {
      selectedProductId: '',
      productNotes: null
    }
  },

  methods: {
    ...mapMutations([
      'addProduct',
      'toggleProductForm'
    ]),

    findSchemaById(id){
      var index = schema.findIndex(obj => obj.productId == id);

      return schema[index]
    },

    handleSelectProduct(){
      this.$nextTick(function () {
        this.handleAddProduct();
      });
    },

    handleAddProduct(){
      if(this.selectedProductId) {
        let newProductEntry = {
          productId: this.selectedProductId,
          name: this.findSchemaById(this.selectedProductId).name,
          notes: this.productNotes,
          items: [{
            data: { ...this.$refs.newProduct.model }
          }]
        };

        this.addProduct(newProductEntry);


        this.closeForm();
      }
    },

    closeForm() {
      this.toggleProductForm();

      this.selectedProductId = DEFAULT_PRODUCT_ID;

      this.productNotes = null;
    },

    getThumbnail(id) {
      if(id){
        return thumbnails[id];
      } else {
        return thumbnails['default'];
      }
    },

    optionIsDisabled(index){
      return this.availableProducts.indexOf(index) == -1;
    }
  },

  computed: {
    ...mapState([
      'project',
      'products',
      'enabled'
    ]),

    ...mapGetters([
      'availableProducts'
    ])
  },

  components: {
    Product
  }

}
</script>

<style src="./scss/product.scss" lang="scss" scoped></style>

<style scoped lang="scss">
  .product-form__container {
     padding-bottom: 36px;
  }

  hr {
    margin-top: 0;
    margin-bottom: 20px;
    border-top: 1px solid #B5D1D9;
  }

  h4 {
    font-family:"DIN Next W01 Bold";
    font-size: 15px;
    color: #0099FF;
    letter-spacing: 0;
    line-height: 20px;
    margin: 0 15px 20px 15px;
  }

  .product-field {
    margin-bottom: 12px;
     
    label { 
      margin-bottom: 10px;
    }
  }

  .row {
    margin-bottom:  0;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
</style>
