/*
  EarthWoolPipe.js
*/

const EarthwoolPipe = {
  productId: 4,
  name: 'Earthwool® 1000°',
  defaultModel: {
    pipeType: null,
    pipeSize: null,
    insulation: null,
    facer: 'ASJ+',
    amount: 1,
  },
  formSchema: {
    fields: [
      {
        type: 'select',
        label: 'Material',
        model: 'pipeType',
        values: ['Iron', 'Copper'],
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled() { return this.$parent.isDisabled },
        onChanged(model, val) {
          model.pipeSize = null;
          model.insulation = null;
        },
      },
      {
        type: 'select',
        label: 'Pipe size',
        model: 'pipeSize',
        values(model, schema) {
          switch(model.pipeType) {
            case 'Iron':
              return ['0.5\"', '0.75\"', '1\"', '1.25\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"', '7\"', '8\"', '9\"', '10\"', '11\"', '12\"', '14\"', '15\"', '16\"', '17\"', '18\"', '19\"', '20\"', '21\"', '22\"', '23\"', '24\"', '25\"', '26\"', '27\"', '28\"', '29\"', '30\"'];
              break;
            case 'Copper':
              return ['0.625\"', '0.875\"', '1.125\"', '1.375\"', '1.625\"', '2.125\"', '2.625\"', '3.125\"', '3.625\"', '4.125\"', '5.125\"', '6.125\"'];
              break;
            default:
              return '';
          }
        },
        onChanged(model, val) {
          model.insulation = null;
        },
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled(model) { return this.$parent.isDisabled || !model.pipeType }
      },
      {
        type: 'select',
        label: 'Pipe insulation',
        model: 'insulation',
        values(model, schema) {
          if(model.pipeType === 'Iron'){
            switch(model.pipeSize) {
              case '0.5\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"' ];
                break;
              case '0.75\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"' ];
                break;
              case '1\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"' ];
                break;
              case '1.25\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"' ];
                break;
              case '1.5\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"' ];
                break;
              case '2\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '2.5\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '3\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '3.5\"':
                return [  '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '4\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '4.5\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '5\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '6\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '7\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '8\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '9\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '10\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '11\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '12\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '14\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '15\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '16\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '17\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '18\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '19\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '20\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '21\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '22\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '23\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"' ];
                break;
              case '24\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"'];
                break;
              case '25\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"' ];
                break;
              case '26\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"' ];
                break;
              case '27\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"' ];
                break;
              case '28\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"' ];
                break;
              case '29\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"' ];
                break;
              case '30\"':
                return [ '1\"', '1.5\"', '2\"', '2.5\"', '3\"' ];
                break;
              default:
                return null;
            }
          }

          if(model.pipeType === 'Copper') {
            switch(model.pipeSize) {
              case '0.625\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"'];
                break;
              case '0.875\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"'];
                break;
              case '1.125\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"'];
                break;
              case '1.375\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"' ];
                break;
              case '1.625\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"' ];
                break;
              case '2.125\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"'];
                break;
              case '2.625\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"'];
                break;
              case '3.125\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"'];
                break;
              case '3.125\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"'];
                break;
              case '3.625\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"'];
                break;
              case '3.625\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"'];
                break;
              case '4.125\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"'];
                break;
              case '5.125\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"'];
                break;
              case '6.125\"':
                return [ '0.5\"', '1\"', '1.5\"', '2\"', '2.5\"', '3\"', '3.5\"', '4\"', '4.5\"', '5\"', '6\"'];
                break;
              default:
                return null;
            }
          }
        },
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled(model) { return this.$parent.isDisabled || !model.pipeType || !model.pipeSize }
      },
      {
        type: 'select',
        label: 'Facer',
        model: 'facer',
        values: ['ASJ+'],
        required: true,
        selectOptions: { hideNoneSelectedText: true },
        styleClasses: 'select-style',
        disabled() { return this.$parent.isDisabled }
      },
      {
        type: 'input',
        inputType: 'text',
        maxlength: 10,
        label: 'Amount',
        model: 'amount',
        pattern: '\\d*',
        onChanged(model, newVal, oldVal, field) {
          newVal = newVal.toString().replace(/\D/g,'');
          model.amount = newVal;
        },
        disabled() { return this.$parent.isDisabled; },
        styleClasses: 'uom-lnft'
      },
    ]
  }
};

export { EarthwoolPipe }