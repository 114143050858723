<template>
	<div class="form-wrap">
		<vue-form-generator v-if="productTypeId" :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
	</div>
</template>

<script>
import VueFormGenerator from "vue-form-generator";
import { schema } from './schema';

export default {
  name: 'product',
  props: [
    'productTypeId',  // Int - the product id
    'product',        // Object - the product model
    'isDisabled',    //  Boolean - disable the form's fields
  ],
  data () {
    return {
    	model: {},

    	schema: {},

    	formOptions: {
    	  validateAfterLoad: true,
    	  validateAfterChanged: true
    	}
    }
  },

  methods: {
  	setFormData: function(id){
  		var index = schema.findIndex(obj => obj.productId == id);

  		if (index !== -1){

        if(this.product) {
          this.$set(this, 'model', this.product);
        } else {
          this.$set(this, 'model', { ...schema[index].defaultModel });
        }

  		  this.$set(this, 'schema', { ...schema[index].formSchema });
  		};
  	}
  },

  watch: {
     'productTypeId': function(){
     	  this.setFormData(this.productTypeId);
     },

     'product': function(){
        this.setFormData(this.productTypeId);
     },
  },

  created: function(){
    this.setFormData(this.productTypeId);
  },

  created: function(){
    this.setFormData(this.productTypeId);
  },

  components: {
  	"vue-form-generator": VueFormGenerator.component
  }
}
</script>

<style lang="scss" scoped>
  .form-wrap {
    width: 583px;
    float: left;
    display: block;


     &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
</style>