/*
index.js
*/

import { EcoBattInsulation } from './EcoBattInsulation';
import { JetStreamWoolInsulation } from './JetStreamWoolInsulation';
import { JetSpray } from './JetSpray';
import { EarthwoolPipe } from './EarthwoolPipe';
import { AtmosphereDuctLiner } from './AtmosphereDuctLiner';
import { AtmosphereDuctWrap } from './AtmosphereDuctWrap';
import { KnUtilityInsulation } from './KnUtilityInsulation';
import { BlackAcousticalBoard } from './BlackAcousticalBoard';
import { AcousticalBoardSmooth } from './AcousticalBoardSmooth';
import { WallCeilingLiner } from './WallCeilingLiner';
import { EarthwoolInsulationBoard } from './EarthwoolInsulationBoard';


const thumbnails = {
  'default': '/assets/img/products/default.svg',
  1: '/assets/img/products/ecobatt.png', // Ecobat
  2: '/assets/img/products/jetstream.png', // Jet Stream
  3: '/assets/img/products/jetspray.png', // JetSpray
  4: '/assets/img/products/earthwool-pipe.jpg', // Earthwool Pipe
  5: '/assets/img/products/atmosphere-liner.png', // Atmosphere Duct Liner
  6: '/assets/img/products/atmosphere-duct-wrap.png', // Atmosphere Duct Wrap
  7: '/assets/img/products/kn-insulation.png', // KN Utility
  8: '/assets/img/products/wall-ceiling.png', // Wall and Ceiling
  9: '/assets/img/products/black-acoustical-board.png', // Black Acoustical Board
  10: '/assets/img/products/acoustical-smooth-board.png', // Acoustical Smooth Board
  11: '/assets/img/products/earthwool-ib.png' // Earthwool Insulation Board
};

const schema = [
	{ ...EcoBattInsulation },
	{ ...JetStreamWoolInsulation },
	{ ...JetSpray },
	{ ...EarthwoolPipe },
	{ ...AtmosphereDuctLiner },
	{ ...AtmosphereDuctWrap },
	{ ...KnUtilityInsulation },
	{ ...BlackAcousticalBoard },
	{ ...AcousticalBoardSmooth },
	{ ...WallCeilingLiner },
	{ ...EarthwoolInsulationBoard }
];

export { schema, thumbnails }