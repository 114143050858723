/*
JetStreamWoolInsulation.js
*/

const JetStreamWoolInsulation = {
  productId: 2,
  name: 'Jet Stream® Ultra',
  defaultModel: {
    blowType: null,
    rValue: null,
    cavityDepth: null,
    amount: 1,
  },
  formSchema: {
    fields: [
      {
        type: 'select',
        label: 'Blow type',
        model: 'blowType',
        values: ['Open blow', 'Closed blow'],
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled() { return this.$parent.isDisabled },
        onChanged(model, val, oldVal, field) {
          model.rValue = null;
          if(val === 'Open blow') model.cavityDepth = 'N/A';
          if(val === 'Closed blow') model.cavityDepth = null;

        }
      },
      {
        type: 'select',
        label: 'R-value',
        model: 'rValue',
        values(model, schema) {
          switch(model.blowType) {
            case 'Open blow':
              return [60, 49, 44, 38, 30, 26, 22, 19, 13, 11];
              break;
            case 'Closed blow':
              return [15, 23, 31, 39];
              break;
            default:
              return null;
           }
        },
        onChanged: function(model, val){
          if(model.blowType === 'Closed blow') {
            switch(val) {
              case 15:
                model.cavityDepth = 3.5;
                break;
              case 23:
                model.cavityDepth = 5.5;
                break;
              case 31:
                model.cavityDepth = 7.25;
                break;
              case 39:
                model.cavityDepth = 9.25;
                break;
              default:
                return null;
             }
          }
        },
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled(model) { return this.$parent.isDisabled || !model.blowType }
      },
      {
        type: 'select',
        label: 'Cavity Depth',
        model: 'cavityDepth',
        values(model, schema) {
          if(model.blowType == 'Open blow') {
            return ['N/A'];
          }

          if(model.blowType == 'Closed blow') {
            switch(model.rValue) {
              case 15:
                return ['3.5\"'];
                break;
              case 23:
                return ['5.5\"'];
                break;
              case 31:
                return ['7.25\"'];
                break;
              case 39:
                return ['9.25\"'];
                break;
              default:
                return [];
             }
          }
        },
        set(model, val) {
          if(model.blowType == 'Open blow') {
            model.cavityDepth = 'N/A';
          }

          if(model.blowType == 'Closed blow') {
            switch(model.rValue) {
              case 15:
                 model.cavityDepth = '3.5\"';
                break;
              case 23:
                model.cavityDepth = '5.5\"';
                break;
              case 31:
                model.cavityDepth = '7.25\"';
                break;
              case 39:
                model.cavityDepth = '9.25\"';
                break;
              default:
                return [];
             }
          }
        },
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        visible(model) { return model && model.blowType === 'Closed blow'},
        disabled(model) { return this.$parent.isDisabled || !model.rValue || !model.blowType }
      },
      {
        type: 'input',
        inputType: 'text',
        maxlength: 10,
        label: 'Amount',
        model: 'amount',
        pattern: '\\d*',
        onChanged(model, newVal, oldVal, field) {
          newVal = newVal.toString().replace(/\D/g,'');
          model.amount = newVal;
        },
        disabled() { return this.$parent.isDisabled; },
        styleClasses: 'uom-ft'
      },
    ]
  },
};

export { JetStreamWoolInsulation }
