// define a mixin object
import { mapMutations } from 'vuex';

export default {

  methods: {
    ...mapMutations([
      'toggleLoginModal',
      'showRegistrationForm'
    ]),

    displayLoginModal(){
      this.toggleLoginModal();
    },

    displayRegistrationForm(){
      this.showRegistrationForm();
    }
  }
}