import "babel-polyfill";
import Vue from 'vue';
import store from './store';
import App from './App';

new Vue({
  store,
  el: '#wrapper',
  delimiters: ['${', '}'],
  components: {
  	App
  }
});