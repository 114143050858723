/*
JetSpray.js
*/

const JetSpray = {
  productId: 3,
  name: 'JetSpray™',
  defaultModel: {
    density: null,
    cavityDepth: null,
    rValue: null,
    amount: 1,
  },
  formSchema: {
    fields: [
      {
        type: 'select',
        label: 'Density, PCF',
        model: 'density',
        values: [1.9, 1.5],
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        onChanged(model, val){
          model.cavityDepth = null;
          model.rValue = null;
        },
        disabled() { return this.$parent.isDisabled }
      },
      {
        type: 'select',
        label: 'Cavity depth',
        model: 'cavityDepth',
        values: ['3.5\"', '5.5\"', '7.25\"', '9.25\"'],
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled(model) { return this.$parent.isDisabled || !model.density}
      },
      {
        type: 'select',
        label: 'R-value',
        model: 'rValue',
        values(model, schema) {
          if(model.density == 1.9){
            switch(model.cavityDepth) {
              case '3.5\"':
                return [15];
                break;
              case '5.5\"':
                return [23];
                break;
              case '7.25\"':
                return [31];
                break;
              case '9.25\"':
                return [39];
                break;
              default:
                return null;
            }
          }

          if(model.density == 1.5){
            switch(model.cavityDepth) {
              case '3.5\"':
                return [14];
                break;
              case '5.5\"':
                return [22];
                break;
              case '7.25\"':
                return [29];
                break;
              case '9.25\"':
                return [37];
                break;
              default:
                return null;
            }
          }
        },
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        set(model, val) {
          if(model.density == 1.9){
            switch(model.cavityDepth) {
              case '3.5\"':
                model.rValue = 15;
                break;
              case '5.5\"':
                model.rValue = 23;
                break;
              case '7.25\"':
                model.rValue = 31;
                break;
              case '9.25\"':
                model.rValue = 39;
                break;
              default:
                return null;
            }
          }

          if(model.density == 1.5){
            switch(model.cavityDepth) {
              case '3.5\"':
                model.rValue = 14;
                break;
              case '5.5\"':
                model.rValue = 22;
                break;
              case '7.25\"':
                model.rValue = 29;
                break;
              case '9.25\"':
                model.rValue = 37;
                break;
              default:
                return null;
            }
          }
        },
        disabled(model) { return this.$parent.isDisabled || !model.density || !model.cavityDepth }
      },
      {
        type: 'input',
        inputType: 'text',
        maxlength: 10,
        label: 'Amount',
        model: 'amount',
        pattern: '\\d*',
        onChanged(model, newVal, oldVal, field) {
          newVal = newVal.toString().replace(/\D/g,'');
          model.amount = newVal;
        },
        disabled() { return this.$parent.isDisabled; },
        styleClasses: 'uom-ft'
      },
    ]
  }
};

export { JetSpray }