<script>
import { mapState } from 'vuex';
import AuthForms  from './mixins/AuthForms';

export default {
  name: 'accountNav',

  mixins: [ AuthForms ],

  props: {
    currentUser: {
      default: false,
      type: [Boolean]
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapState([
      'userId'
    ]),

    userIsAuthenticated(){
      let auth = this.userId || this.currentUser;
      return auth; 
    }
  }
}
</script>