<script>
import { mapState, mapMutations } from 'vuex';
import Modal from './Modal.vue'

export default {
  name: 'contactModal',

  data() {
    return {
      firstname: window.__USER__.firstname ? window.__USER__.firstname : null,
      lastname: window.__USER__.lastname ? window.__USER__.lastname : null,
      email: window.__USER__.email ? window.__USER__.email : null,
      phone: window.__USER__.phone ? window.__USER__.phone : null,
      company: window.__USER__.company ? window.__USER__.company : null,
      title: window.__USER__.title ? window.__USER__.title : null,
      quote: false,
      projectName: null,
      projectAddress: null,
      city: null,
      zip: null,
      classNames: { modalContainer: ['contact'] }
    }
  },

  computed: {
    ...mapState([
      'showContactModal',
      'contactProducts'
    ]),

    validates(){
      const contactValidates = this.firstname &&
        this.lastname &&
        this.email &&
        this.company;

      const quoteValidates = this.title &&
        this.projectName &&
        this.projectAddress &&
        this.city &&
        this.zip;

      if(this.quote) { // request quote selected
        if ( contactValidates && quoteValidates ) return true;
        return false;
      } else {
        if ( contactValidates ) return true;
        return false;
      }
    },

    quoteDisabled(){
      if(!this.quote){ // return quote checkbox unchecked
        return true;
      }
      return false;  // return quote checkbox checked
    }
  },

  methods: {
    handleClose(){
      this.toggleContactModal();
    },

    ...mapMutations([
      'toggleContactModal',
    ])
  },

  components: {
    Modal
  }
}
</script>

<style lang="scss" scoped>

.modal-body {
  display: block;
  padding: 0 40px;

  .col {
    width: 50%;
    vertical-align: top;
    padding: 6px 22px 6px 0;
    display: table-cell;
    border-right: 1px solid #C7C7C6;

    &:last-child {
      padding: 6px 0 6px 22px;
      border-right: none;
    }
  }

  h2 {
    color: #0099FF;
    font-size: 23px;
    margin-bottom: 23px;
    font-family: "DIN Next W01 Medium";
  }

  p {
    font-size: 13.5px;
    margin-bottom: 10px;

    &.errors {
      font-weight: bold;
      color: red;
    }
  }

  .button.primary, {
    margin-top: 32px;
  }

  .field-group {
    clear: both;
    margin-bottom: 12px;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .col:first-child {
    label {
        width: 100px;
    }

    input, textarea {
      width: 250px;
    }

    .checkbox {
    width: 230px;
    margin-left: 128px;


    input[type='checkbox'] {
      display: block;
      width: auto;
      margin-left: -20px;
      margin-top: 6px;
    }

    label {
      float: none;
      display: inline;
      padding: 0;
      width: auto;
    }
  }
  }

  .col:last-child {
    label {
        width: 130px;
    }

    input, textarea {
      width: 230px;
    }

    .checkbox {
      input[type='checkbox'] {
        height: 22px;
        margin-right: 10px;
        display: inline;
        width: auto;
      }

      label {
        display: inline;
        padding: 0;
        width: auto;
      }
    }
  }

  label {
    display: block;
    float: left;
    margin-right: 8px;
    text-align: right;
    padding: 4px 0 0 0;
  }

  input, textarea {
    display: inline-block;
    float: left;
  }

  textarea {
    height: 114px;
    width: 245px;
  }

  p { color: #4D4D4D; }

  hr {
    border: 0;
    height: 1px;
    background: #B5D1D9;
  }

  button { float: right; }

  p
  input,
  label,
  textarea {
    transition: opacity .2s linear;
  }

}

.is-disabled {
    p { opacity: 0.35; transition: opacity .2s linear; }

   .checkbox input,
   .checkbox label {
      opacity: 1 !important;
   }
}
</style>
