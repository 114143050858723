<template>
  <modal
  :show="showGlossaryModal"
  :class-names="classNames"
  @close="handleClose()"
  v-cloak>
    <div class="modal-body">
      <h2>Insulation glossary</h2>
        <table>
          <tr>
            <td>Amount</td>
            <td>Total amount of either square footage (SF) or lineal footage (LF) required to complete the project</td>
          </tr>
          <tr>
            <td>ASJ+ - all service jacket</td>
            <td>Total amount of either square footage (SF) or lineal footage (LF) required to complete the Aluminum foil, reinforced with a glass scrim bonded to a kraft paper interleaving with an outer film layer leaving no paper exposed.</td>
          </tr>
          <tr>
            <td>BTU (British Thermal Unit)</td>
            <td>Heat required to raise temperature of one pound of water by one degree; around the same amount of energy released by one wooden match.</td>
          </tr>
          <tr>
            <td>Cavity depth</td>
            <td>Depth of either wood or metal framing components used to determine the amount of insulation that can be installed within a given cavity.</td>
          </tr>
          <tr>
            <td>Class I vapor retarder</td>
            <td>0.1 perm or less</td>
          </tr>
          <tr>
            <td>Class II vapor retarder</td>
            <td>1.0 perm or less</td>
          </tr>
          <tr>
            <td>Class III vapor retarder</td>
            <td>10 perm or less</td>
          </tr>
          <tr>
            <td>Closed blow</td>
            <td>A netted application of loosefill insulation where the net holds the insulation in place during installation and remains in place for the life of the system</td>
          </tr>
          <tr>
            <td>C-value</td>
            <td>Heat flow property, conductance, of a product at a given thickness. The lower the C, the better the insulator.</td>
          </tr>
          <tr>
            <td>Density</td>
            <td>Amount of solid material, expressed in pounds per cubic foot (PCF).</td>
          </tr>
          <tr>
            <td>Emittance</td>
            <td>Used for measuring the resistance of a material to radiative heat transfer. Typical values are 0.02 - 1, with the lower values being better radiant barriers.</td>
          </tr>
          <tr>
            <td>Facer</td>
            <td>Covering adhered to base glass mineral wool material. Used primarily as a vapor retarder, and secondarily for cleanability, color, or integral structural components.</td>
          </tr>
          <tr>
            <td>FSK- foil scrim kraft</td>
            <td>Laminate composed of thin layer of aluminum foil, glass fiber reinforcing scrim, and kraft paper.</td>
          </tr>
          <tr>
            <td>Installed R-value (Duct wrap)</td>
            <td>Laminate composed of thin layer of aluminum foil, glass fiber reinforcing scrim, and kraft paper.</td>
          </tr>
          <tr>
            <td>K-value</td>
            <td>Heat flow thickness of a homogeneous material. The lower the k, the better the insulator.</td>
          </tr>
          <tr>
            <td>Open blow</td>
            <td>Loosefill insulation with integral adhesive, directly applied to the wall cavity without need for additional netting</td>
          </tr>
          <tr>
            <td>Perm</td>
            <td>Unit of permeance of water vapor transmission where a difference in pressure exists on either side of a material or membrane (US perm = 1 grain of water vapor per hour, per square foot, per inch of mercury).</td>
          </tr>
          <tr>
            <td>Pipe size</td>
            <td>Pipe sizes are expressed in imperial units for either iron pipe (Iron Pipe Size - IPS) or copper tube size (CTS)</td>
          </tr>
          <tr>
            <td>PSK- poly scrim kraft</td>
            <td>String reinforced polyethylene kraft facing.</td>
          </tr>
          <tr>
            <td>R-value</td>
            <td>Ability of material, or building section to retard heat flow. The higher the R, the better the insulator.</td>
          </tr>
          <tr>
            <td>U-value</td>
            <td>Heat flow property of an entire building section. The lower the U, the better the insulating value.</td>
          </tr>
          <tr>
            <td>Vapor retarder</td>
            <td>Thin layer of material varying in permeance, included in building construction to slow moisture migration. Vapor retarders are split into three classes.</td>
          </tr>
        </table>
    </div>
  </modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Modal from './Modal.vue'

export default {
  name: 'glossaryModal',

  data() {
    return {
      classNames: { modalContainer: ['glossary'] }
    }
  },

  computed: {
    ...mapState([
      'showGlossaryModal'
    ]),
  },

  methods: {
    ...mapMutations([
      'toggleGlossaryModal'
    ]),

    handleClose(event){
      if(event) event.preventDefault();
      this.toggleGlossaryModal();
    },
  },

  components: {
    Modal
  }
}
</script>

<style lang="scss" scoped>
.modal-body {
  display: block;
  padding: 16px 30px;
}

h2 {
  font-size: 23px;
  margin-bottom: 30px;
  color: #0099FF;
}

table tr {
  border-bottom: 1px solid #B5D1D9;

  &:last-child {
    border-bottom: none;
  }

  td {
    padding: 15px 10px;
    font-size: 13.5px;
    color: #4D4D4D;
  }

  td:first-child {
    min-width: 180px;
    text-align: right;
    font-weight: bold;
  }
}
</style>
