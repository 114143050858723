<template>
  <div>

    <div v-if="!initProjectId && initProductId && userId" class="field-group project-list">
      <label for="project-import">Add products to this project</label>
      <div class="field-wrap">
        <select id="project-import" v-model="selectProjectId" @change="handleSelectProject()">
          <option value="" disabled>Select a project</option>
          <option v-for="project in projects" v-bind:value="project.id">
           {{ project.name }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="!initProjectId && initProductId && userId" class="separator">
      <span>OR</span>
    </div>

    <div class="field-group">
      <label for="project-name">Project name<span class="required">*</span></label>
      <input id="project-name" v-model="name" placeholder="">
    </div>

    <div class="field-group">
      <label for="client">Client or group</label>
      <input id="client" v-model="client" placeholder="">
    </div>

    <div class="field-group">
      <label for="category">Category</label>
      <div class="field-wrap">
        <select id="category" v-model="category">
            <option disabled value="">Select one</option>
            <option>Dormitories</option>
            <option>Education</option>
            <option>Government</option>
            <option>Healthcare</option>
            <option>Hotel</option>
            <option>Manufacturing</option>
            <option>Multi family</option>
            <option>Office</option>
            <option>Parking garage</option>
            <option>Recreation</option>
            <option>Religious</option>
            <option>Retail</option>
            <option>Single family</option>
            <option>Transportation</option>
            <option>Warehouse (non-mfg)</option>
        </select>
      </div>
    </div>

    <div class="field-group">
      <label for="Description">Description</label>
      <textarea id="description" v-model="description"  placeholder="Can include project background info, summarized design brief and efficiency strategies to be explored. There is no character limit." />
    </div>

    <div v-if="productButtonsAreVisible" class="button-group">
      <!-- App intialized WITHOUT a product selected. No products are pre-selected and bottom section is hidden.   -->
      <button class="button primary" v-if="!initProductId" v-on:click="toggleProducts" :disabled="!name">Select products</button>

      <!-- App intialized WITH a product selected. Product is preselected and bottom section is visible but disabled. -->
      <button class="button primary" v-if="initProductId && products.length" v-on:click="configureProducts" :disabled="!name">Configure products</button>

      <!-- App intialized WITH a product id but all products have been removed. Bottom section has been hidden. -->
      <button class="button primary" v-if="initProductId && !products.length" v-on:click="toggleProductForm" :disabled="!name">Select products</button>


      <button class="button" v-on:click="saveProject" :disabled="!name">Save project</button>
    </div>

  </div>
</template>

<script>
import { HTTP } from './http-common';
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'projectForm',

  data () {
    return {
      selectProjectId: '',
      projects: null,
    }
  },

  computed: {
    ...mapState([
      'formInitalized',
      'project',
      'products',
      'initProductId',
      'initProjectId',
      'userId',
      'productFormIsVisible'
    ]),

    name: {
      get () {
        return this.project.name;
      },
      set (value) {
        this.setProjectName(value);
      }
    },

    client: {
      get () {
        return this.project.client;
      },
      set (value) {
        this.setProjectClient(value);
      }
    },

    category: {
      get () {
        return this.project.category || '';
      },
      set (value) {
        this.setProjectCategory(value);
      }
    },

    description: {
      get () {
        return this.project.description;
      },
      set (value) {
        this.setProjectDescription(value);
      }
    },

    productButtonsAreVisible() {
      if (this.initProductId && !this.formInitalized) {
        return true
      } else {
        return !this.productFormIsVisible && !this.products.length;
      }
    }
  },

  methods: {
    ...mapMutations([
      'handleError',
      'toggleProductForm',
      'setInitProjectId',
      'setProjectName',
      'setProjectClient',
      'setProjectCategory',
      'setProjectDescription',
      'isEnabled',
      'isInitalized',
      'addProduct',
      'toggleSpinner'
    ]),

    ...mapActions([
      'initProject',
      'saveProject'
    ]),

    configureProducts () {
      this.isInitalized();
      this.isEnabled();
    },

    toggleProducts () {
      if(!this.formInitalized) {
        this.isInitalized();
        this.isEnabled();
      }

      this.toggleProductForm();
    },

    handleSelectProject() {
      if(this.selectProjectId) {
        this.setInitProjectId(this.selectProjectId);
        this.toggleSpinner();
        this.initProject();
      }
    },

    loadProjects () {
      if(this.userId) {
        HTTP.get('projects')
          .then(response => {
            this.projects = response.data.data;
          })
          .catch(e => {
            this.handleError(e);
          });
      }
    }
  },

  watch: {
    userId () {
      this.loadProjects();
    }
  },

  beforeMount () {
    this.loadProjects();
  }

}
</script>

<style scoped lang="scss">

  .separator {
    clear: both;
    margin: 1.5em 0;

    span {
      font-size: 13.5px;
      color: #4D4D4D;
      letter-spacing: 0;
      font-weight: bold;
      margin-left: 120px;
    }
  }

  .field-group.project-list label {
    line-height: 1.5em;
    margin-top: -2px;
  }

  .field-group {
    margin-bottom: 12px;

    label {
      display: inline-block;
      float: left;
      text-align: right;
      width: 110px;
      font-size: 13.5px;
      color: #4D4D4D;
      letter-spacing: 0;
      line-height: 32px;
      margin-right: 12px;
    }

    input,
    textarea {
      display: inline-block;
      font-size: 13px;
      color: #4D4D4D;
      background: #FFFFFF;
      border: 1px solid #DFE6EE;
      border-radius: 2px;
      padding: 9px 10px;
      width: 330px;
      &:-webkit-input-placeholder ,
      &:-moz-placeholder,
      &:-ms-input-,
      &:-moz-placeholder,
      &:-moz-placeholder {
        font-size: 13px;
        color: #B0BAC5;
        letter-spacing: 0;
      }
    }

    textarea {
     resize: none;
     width: 330px;
     height: 114px;
    }

    .field-wrap,
    select {
      width: 330px;
    }

  }

  .button-group {
    text-align: right;
    margin-right: 188px;

    .button {
      margin-left: 5px;
    }
  }
</style>
