/*
  AcousticalBoardSmooth.js
*/

const AcousticalBoardSmooth = {
  productId: 10,
  name: 'Acoustical Smooth Board',
  defaultModel: {
    density: null,
    thickness: null,
    amount: 1,
  },
  formSchema: {
    fields: [
      {
        type: 'select',
        label: 'Density, PCF',
        model: 'density',
        values: [3, 4.25, 6],
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled() { return this.$parent.isDisabled },
        onChanged(model, val) {
          model.thickness = null;
        },
      },
      {
        type: 'select',
        label: 'Thickness',
        model: 'thickness',
        values(model, schema) {
          switch(model.density) {
            case 3:
              return [ '1\"', '1.5\"', '2\"', '3\"' ];
              break;
            case 4.25:
              return [ '0.5\"', '1\"', '1.5\"', '2\"' ];
              break;
            case 6:
              return [ '0.5\"', '0.75\"', '1\"', '1.5\"', '2\"' ];
              break;
            default:
              return null;
          }
        },
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled(model) { return this.$parent.isDisabled || !model.density}
      },
      {
        type: 'input',
        inputType: 'text',
        maxlength: 10,
        label: 'Amount',
        model: 'amount',
        pattern: '\\d*',
        onChanged(model, newVal, oldVal, field) {
          newVal = newVal.toString().replace(/\D/g,'');
          model.amount = newVal;
        },
        disabled() { return this.$parent.isDisabled; },
        styleClasses: 'uom-ft'
      },
    ]
  }
};

export { AcousticalBoardSmooth }