import { mapMutations } from 'vuex';
import AuthForms  from './mixins/AuthForms';
import AccountNav from './AccountNav.vue';
import Configurator from './Configurator.vue';
import LoginModal from './LoginModal.vue';
import GlossaryModal from './GlossaryModal.vue';
import ContactModal from './ContactModal.vue';
import UserAccountForm from './UserAccountForm.vue';
import { BounceLoader  } from 'vue-spinner/dist/vue-spinner.min.js';

export default {
  name: 'app',

  mixins: [ AuthForms ],

  data(){
    return {}
  },

  methods : {
    ...mapMutations([
      'toggleContactModal',
      'toggleGlossaryModal'
    ]),

    displayContactModal(event, string){
      if (event) event.preventDefault();
      this.toggleContactModal(string);
    },

    displayGlossaryModal(event){
      if (event) event.preventDefault();
      this.toggleGlossaryModal();
    },
  },

  components: {
    AccountNav,
    Configurator,
    LoginModal,
    BounceLoader,
    GlossaryModal,
    ContactModal,
    UserAccountForm
  }
}