<script>
export default {
  name: 'userAccountForm',

  data() {
    return {
      firstname: window.__USER__.firstname ? window.__USER__.firstname : null,
      lastname: window.__USER__.lastname ? window.__USER__.lastname : null,
      email: window.__USER__.email ? window.__USER__.email : null,
      phone: window.__USER__.phone ? window.__USER__.phone : null,
      company: window.__USER__.company ? window.__USER__.company : null,
      title: window.__USER__.title ? window.__USER__.title : null,
      password: null,
      newPassword: null,
      confirmPassword: null,
      setNewPassword: false
    }
  },

  methods: {
    changePassword(event){
      this.password = null;
      if(event) event.preventDefault();
      this.setNewPassword = !this.setNewPassword;
    }
  },

  computed: {
    validates(){
      const formValidates = this.firstname &&
        this.lastname &&
        this.email &&
        this.password;

      const passwordsMatch = this.newPassword && this.confirmPassword ? this.newPassword === this.confirmPassword : false;

      if(this.setNewPassword) {
        return this.firstname && this.lastname && this.email &&  passwordsMatch;
      } else {
        return formValidates;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
