<template>
  <div>
    <section>

      <div class="step-one">
        <h3>
         Select products, configure attributes and specify quantities,<br> save to your project library, get spec help and request a quote.
        </h3>

        <ul class='error-list' if="errors.length > 1">
          <li v-for="error in errors">
            {{ error }}
          </li>
        </ul>

        <div class="headline">
          <span>1</span><h4>Set up your project</h4>
        </div>

        <project-form></project-form>
      </div>

      <aside class="page__sidebar">
        <div class="cta cta__blue">
          <h4>What is a project? What do you get when you build one?</h4>
          <p>
            <strong>1. Set up your project</strong><br>
            A project can be one you’re working on, or a type of project you typically work on. Client, category and description are there to help you produce documentation for your project.
          </p>
          <p>
            <strong>2. Select and configure products</strong><br>
            Identify all the types of insulation products needed for each application in the building.
            The notes fields are there for knowledge-sharing, questions and comments.
          </p>
          <p>
            <strong>Save projects in your Project Library</strong><br>
            Projects include:<br>
            <ol>
              <li>Project description</li>
              <li>Product specifications</li>
              <li>Transparency Reports &amp; Material Health Overviews for each product specified are available online or downloadable for printing and sharing.</li>
            </ol>
          </p>
        </div>
      </aside>
    </section> <!-- //end .step-one -->

    <section class="step-two">
      <div v-show="productFormIsVisible || products.length > 0" class="headline">
        <span>2</span><h4>Select and configure products</h4>
        <div class="row">
          <p>Fill in as much information as you know. All fields are optional.</p>
          <a href="#" class="glossary" v-on:click="toggleGlossaryModal">Insulation glossary</a>
        </div>
      </div>

      <product-list v-if="products.length > 0"></product-list>

      <product-form v-show="productFormIsVisible"></product-form>

      <div class="new-product-actions" v-show="productFormIsVisible || products.length > 0">
        <button class="button primary"
          v-show="!productFormIsVisible"
          v-on:click="toggleProductForm"
          v-bind:disabled="!enabled">
            Add a product
        </button>

        <button class="button"
          v-on:click="saveProject"
          v-bind:disabled="!enabled">
            Save project
        </button>
      </div>
    </section><!-- //end .step-two -->
    <project-saved-modal></project-saved-modal>
  </div>
</template>

<script>
import { schema } from './schema';
import { mapState, mapMutations, mapActions } from 'vuex'
import ProjectForm from './ProjectForm.vue';
import ProductList from './ProductList.vue';
import ProductForm from './ProductForm.vue';
import ProjectSavedModal from './ProjectSavedModal.vue';
import VueFormGenerator from 'vue-form-generator';

export default {
  name: 'configurator',

  props: {

    initProductId: {
      default: false,
      type: [Boolean, Number]
    },

    initProjectId: {
      default: false,
      type: [Boolean, Number]
    },

    userId: {
      default: false,
      type: [Boolean, Number]
    }
  },

  data () {
    return {}
  },

  computed: {
    ...mapState([
      'errors',
      'enabled',
      'products',
      'project',
      'productFormIsVisible'
    ])
  },

  methods: {
    ...mapMutations([
      'setErrorMessage',
      'toggleProductForm',
      'setInitProductId',
      'setInitProjectId',
      'setUserId',
      'addProduct',
      'isInitalized',
      'isEnabled',
      'toggleSpinner',
      'toggleGlossaryModal'
    ]),

    ...mapActions([
      'initProject',
      'saveProject'
    ]),

    findSchemaById: function(id){
      var index = schema.findIndex(obj => obj.productId == id);

      return schema[index]
    },

  },

  beforeMount () {
    this.setInitProductId(this.initProductId);
    this.setInitProjectId(this.initProjectId);
    this.setUserId(this.userId);

    if (this.initProjectId)
    {
      this.toggleSpinner();
      this.initProject();
    }
    else if (this.initProductId)
    {
      let schema = this.findSchemaById(this.initProductId);

      if(schema) {
        let newProductEntry = {
          id: Math.floor(Math.random() * 1000) + 1 ,
          productId: this.initProductId,
          name: schema.name,
          notes: null,
          items: [{
            data: { ...schema.defaultModel }
          }]
        };

        this.addProduct(newProductEntry);
      } else {
        this.setErrorMessage('Error: Product ID #'+this.initProductId+' not found');
      }

    }
  },

  components: {
    ProjectForm,
    ProductList,
    ProductForm,
    ProjectSavedModal,
    "vue-form-generator": VueFormGenerator.component
  }
}
</script>

<style scoped lang="scss">
/* local styles */

.cta__blue ol {
  margin: 0;
}

.step-one {
  width: 641px;
  float: left;
  margin-right: 15px;
  margin-bottom: 3em;

  h3 {
    font-family: "DIN Next W01 Medium";
    font-weight: normal;
    font-size: 17px;
    color: #0099FF;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 60px;
  }
}

.step-two {

  p { display: inline; }

  a.glossary {
    display: inline;
    float: right;
    font-size: 13.5px;
    color: #134FBD;
    line-height: 19px;
  }
}

section {
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .headline {
    border-bottom: 1px solid #B5D1D9;
    margin-bottom: 20px;

    h4 {
      display: inline;
      font-family:"DIN Next W01 Bold";
      font-size: 15px;
      color: #0099FF;
      letter-spacing: 0;
      line-height: 20px;
    }

    span {
      font-family:"Proxima N W15 Bold";
      display: inline-block;
      background-color: #FE7C00;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      padding-top: 1px;
      margin-right: 10px;
      margin-bottom: 8px;
      text-align: center;
      font-weight: bold;
      font-size: 15px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 19px;
    }

    .row {
      margin-top: -8px;
    }

    p {
      margin: 0 0 14px 36px;
      font-size: 13.5px;
      color: #4D4D4D;
      letter-spacing: 0;
      line-height: 1;
    }
  }
}

.error-list {
  color: red;
  font-weight: bold;
}

.new-product-actions {
  margin-top: 0;
  padding: 16px 0;
  text-align: left;
  border-top: 1px solid #B5D1D9;

  .button {
    margin-right: 4px;
  }

  .button + .button {
        margin-left: 0;
  }
}
</style>