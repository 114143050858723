<template>
  <modal
  :show="showProjectSavedModal"
  :class-names="classNames"
  @close="handleClose()"
  v-cloak>
    <div class="modal-body">
      <h2>Your changes have been saved.</h2>
      <p>You may continue to update product specifications or go to your Project Library.</p>
      <hr>
      <div class="actions">
        <a href="/account/library/knauf-insulation" class="button primary">Go to Project Library</a>
        <a href="#" class="button" v-on:click="handleClose($event)">Continue updating</a>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Modal from './Modal.vue'

export default {
  name: 'projectSavedModal',

  data() {
    return {
      classNames: { modalContainer: ['saved-project'] }
    }
  },

  computed: {
    ...mapState([
      'showProjectSavedModal'
    ]),
  },

  methods: {
    ...mapMutations([
      'toggleProjectSavedModal'
    ]),

    handleClose(event){
      if(event) event.preventDefault();
      this.toggleProjectSavedModal();
    },
  },

  components: {
    Modal
  }
}
</script>

<style lang="scss" scoped>
.modal-body {
  display: block;
  padding: 6px 30px;
}

h2 {
  font-size: 23px;
  margin-bottom: 30px;
  color: #0099FF;
}

p {
  font-weight: bold;
  max-width: 360px;
}

.actions {
  text-align: right;
}

hr {
  margin: 60px 0 10px 0;
}
</style>
