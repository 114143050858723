/*
  AtmosphereDuctLiner.js
*/

const WallCeilingLiner = {
  productId: 8,
  name: 'Wall and Ceiling Liner',
  defaultModel: {
    density: null,
    thickness: null,
    rValue: null,
    amount: 1,
  },
  formSchema: {
    fields: [
      {
        type: 'select',
        label: 'Density, PCF',
        model: 'density',
        values: [1.5, 2],
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled() { return this.$parent.isDisabled },
        onChanged(model, val) {
          model.thickness = null;
          model.rValue = null;
        },
      },
      {
        type: 'select',
        label: 'Thickness',
        model: 'thickness',
        values(model, schema) {
          switch(model.density) {
            case 1.5:
              return [ '1\"', '1.5\"', '2\"' ];
              break;
            case 2:
              return [ '0.5\"', '1\"', '1.5\"' ];
              break;
            default:
              return null;
          }
        },
        onChanged(model, val) {
          switch(val) {
            case '1\"':
              model.rValue = 4.2;
              break;
            case '1.5\"':
              model.rValue = 6;
              break;
            case '2\"':
              model.rValue = 8;
              break;
            case '0.5\"':
              model.rValue = 2.1;
              break;
            case '1\"':
              model.rValue = 4.2;
              break;
            case '1.5\"':
              model.rValue = 6.3;
              break;
            default:
              return null;
          }
        },
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled(model) { return this.$parent.isDisabled || !model.density}
      },
      {
        type: 'select',
        label: 'Installed R-value',
        model: 'rValue',
        values(model, schema) {
          switch(model.thickness) {
            case '1\"':
              return [4.2];
              break;
            case '1.5\"':
              return [6];
              break;
            case '2\"':
              return [8];
              break;
            case '0.5\"':
              return [2.1];
              break;
            case '1\"':
              return [4.2];
              break;
            case '1.5\"':
              return [6.3];
              break;
            default:
              return null;
          }
        },
        required: true,
        selectOptions: { noneSelectedText: 'Select' },
        styleClasses: 'select-style',
        disabled(model) { return this.$parent.isDisabled || !model.density || !model.thickness}
      },
      {
        type: 'input',
        inputType: 'text',
        maxlength: 10,
        label: 'Amount',
        model: 'amount',
        pattern: '\\d*',
        onChanged(model, newVal, oldVal, field) {
          newVal = newVal.toString().replace(/\D/g,'');
          model.amount = newVal;
        },
        disabled() { return this.$parent.isDisabled; },
        styleClasses: 'uom-ft'
      },
    ]
  }
};

export { WallCeilingLiner }