<script>
import { HTTP } from './http-common';
import { BounceLoader  } from 'vue-spinner/dist/vue-spinner.min.js';
import { mapState, mapMutations, mapActions } from 'vuex';
import Modal from './Modal.vue'

export default {
  name: 'loginModal',

  data() {
    return {
      register: {
        firstName: null,
        email: null,
        password: null,
        passwordConfirm: null
      }
    }
  },

  computed: {
    getClasses(){
      if(this.showRegistrationForm) return { modalContainer: ['registration'] }
      return { modalContainer: ['login'] }
    },

    regValidates(){
      if(this.register.password && this.register.passwordConfirm && this.register.firstName && this.register.email) {
        if(this.register.password === this.register.passwordConfirm) return true;
      }

      return false;
    },

    ...mapState([
      'showLoginModal',
      'showLoginSpinner',
      'showRegistrationForm',
      'loginErrorMessage',
      'newUserErrors',
      'userId',
      'isLoading'
    ]),
  },

  methods: {
    ...mapMutations([
      'setRegistrationForm',
      'closeLoginModal'
    ]),

    ...mapActions([
      'loginUser',
      'registerNewUser'
    ]),

    handleClose(){
      this.closeLoginModal();
    },

    handleLogin(){
      if(!this.isLoading) { // if we're not already processing a request
        let loginForm = document.getElementById('user_login_form');

        let formData = new FormData(loginForm);
      
        this.loginUser(formData);
      }
    },

    handleNewUser(){
      if(!this.isLoading) { // if we're not already processing a request
        let loginForm = document.getElementById('user_registration_form');

        let formData = new FormData(loginForm);
      
        this.registerNewUser(formData);
      }
    }
  },

  components: {
    Modal,
    BounceLoader
  }
}
</script>

<style lang="scss" scoped>

.modal-body {
  display: table;

  &.registration {
    display: block;
    padding: 6px 30px;

    label { width: 118px; }
    input { width: 210px; }
    a.button.plain  { margin-left: 120px; padding: 14px 0; }
    p {
      margin-bottom: 30px;
    }

    .field-group button {
      margin-left: 130px;
    }

  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .col {
    width: 50%;
    vertical-align: top;
    padding: 6px 22px;
    display: table-cell;
    border-right: 1px solid #C7C7C6;

    &:last-child {
      border-right: none;
    }
  }

  .left-col,
  .right-col
  {
    float: left;
  }

  .left-col {
    width: 360px;
    padding-right: 10px;
  }

  .right-col {
    width: 237px;
    text-align: right;
  }

  h2, h4 {
    color: #0099FF;
  }

  h2 {
    font-size: 23px;
    margin-bottom: 33px;
  }

  h4 {
    font-size: 15px;
    margin-bottom: 30px;
  }

  p {
    font-size: 13.5px;
    margin-bottom: 10px;

    &.errors {
      font-weight: bold;
      color: red;
    }
  }

  .button.primary, {
    margin-top: 32px;
  }

  label {
    text-align: right;
    width: 90px;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 10px;
    font-size: 13.5px;
    color: #4D4D4D;
    padding: 0;
  }

  input {
    width: 260px;
  }

  .errors {
    margin-left: 122px;
    width: 230px;

    li {
      background: #FFEBED;
      font-size: 14px;
      padding: 4px 10px;
      font-weight: bold;
      color: #D0021B;
      letter-spacing: 0;
    }
  }
  .error-check { 
    display: none; 
    color: #D0021B;
    font-weight: bold;
    font-size: 14px;
    margin-left: 6px;
  }

  .field-group {
    margin-bottom: 12px;
    vertical-align: middle;

    button {
      display: inline-block;
      margin-left: 102px;
    }
    a { float: right; padding: 8px; }

    &.error {

      input { 
        border: 1px solid #E1464E; 
      }

      .error-check {
        display: inline-block;
      }
    }
  }
}
</style>
